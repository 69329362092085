<h2 mat-dialog-title>Thanh toán khoản thu cho <strong>{{data.full_name}}</strong></h2>
<mat-dialog-content>
    <div class="">
        <div>

            <div *ngIf="dataList.length == 0" class="text-center">
                <app-nodata msg="Chưa có khoản thu nào được gán"></app-nodata>
            </div>
            <div *ngIf="dataList.length > 0" style="
            max-height: 80vh;" class="qbs_scroll">
                <table class="table table-head-purple table-hover" id="datatable">
                    <thead class="thead-default thead-lg">
                        <tr>
                            <th style="width: 50px; text-align: center;">#</th>
                            <th>Code</th>
                            <th>Khoản thu</th>
                            <th>Giá trị</th>
                            <!-- <th style="text-align: center">Kỳ thu</th> -->
                            <th class="text-right">Đã thanh toán</th>
                            <th class="text-right">Thừa/thiếu</th>
                            <th class="text-center">Thời gian</th>
                            <th class="text-center">Hình thức</th>
                            <!-- <th class="text-center">Phút tính sơm</th>
                        <th class="text-center">Phút tính muộn</th> -->
                            <th class="text-right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of dataList; let i = index">
                            <tr *ngIf="item.code_bidv_merge == ''" [ngClass]="{'row-disabled': item.temp_checked_origin == 0, 'row-checked' : item.temp_checked, 'row-paid' : item.status == 2}">
                                <td>
                                    <a *ngIf="item.temp_checked_origin == 1 && item.status < 2" class="avatar-img-list" (click)="showQRcode(item)" href="javascript:;">
                                        <span style="font-size: 32px;" class="material-icons"> qr_code_2 </span>
                                    </a>
                                    <img *ngIf="item.temp_checked_origin == 1 && item.status == 2" class="img-circle mt-1" src="./assets/img/icons/check-mark.png" alt="image" width="30" />
                                </td>
                                <td>
                                    <div style="font-size: 18px; font-weight: bold;">{{item.code_bidv}}</div>
                                </td>

                                <td>
                                    <div style="font-size: 18px;">{{item.name}}</div>
                                    <ng-container *ngIf="item.finance_type == 'dot'">
                                        <table style="width: 100%;">
                                            <tr *ngIf="item.pri_debt > 0" style="background-color: #faebd7;">
                                                <td style="padding: 3px 6px; vertical-align: middle;">
                                                    <span class="material-icons mr-1 font-14 text-secondary"> add_circle
                                                    </span>Tháng trước thiếu
                                                </td>
                                                <td style="padding: 3px 6px; text-align: right; vertical-align: middle;">
                                                    <span class="ml-4">{{item.pri_debt | qbscurrency}}</span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="item.pri_credit > 0" style="background-color: #faebd7;">
                                                <td style="padding: 3px 6px; vertical-align: middle;">
                                                    <span class="material-icons mr-1 font-14 text-secondary">
                                                        remove_circle </span>Tháng trước dư
                                                </td>
                                                <td style="padding: 3px 6px; text-align: right; vertical-align: middle;">
                                                    <span class="ml-4">{{item.pri_credit | qbscurrency}}</span>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let jtem of item.finance_content_json; let i = index">
                                                <td style="padding: 3px 6px;">
                                                    <span class="material-icons font-16 text-secondary">
                                                        noise_control_off </span>{{jtem.label}}
                                                </td>
                                                <td style="padding: 3px 6px; text-align: right; vertical-align: middle;">
                                                    <span class="ml-4">{{jtem.value | qbscurrency}}</span>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- <span class="badge badge-secondary mr-2" *ngFor="let jtem of item.finance_content_json; let i = index"> {{jtem.label}}</span> -->
                                    </ng-container>
                                </td>
                                <td class="text-right">
                                    <div style="font-size: 18px; font-weight: bold;">{{item.finance_cate_value| qbscurrency}}
                                    </div>
                                </td>


                                <td class="text-right " [ngClass]="{'text-primary': item.payment_value > 0}">
                                    <div *ngIf="item.status == 2" style="font-size: 18px; font-weight: bold;">
                                        {{item.payment_value| qbscurrency}}
                                    </div>
                                    <div *ngIf="item.status == 1" class="fin-value-cover">
                                        <input type="text" [(ngModel)]="item.payment_value" [name]="'finance_cate_value'+i" currencyMask class="fin-value-input" maxlength="10" />
                                        <button (click)="updateFinanceStudent(item)" class="btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Cập nhật">
                                            <span *ngIf="!isLoading" class="material-icons"> done </span>
                                            <span *ngIf="isLoading" class="spinner-border"></span>
                                        </button>
                                    </div>
                                </td>
                                <td class="text-right " [ngClass]="{'text-danger': item.payment_diff != 0}">
                                    <div *ngIf="item.status == 2" style="font-size: 18px; font-weight: bold;">
                                        {{item.payment_diff | qbscurrency}}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div style="max-width: 90px; font-size: small;">{{item.payment_time_str}}</div>
                                </td>
                                <td class="text-center">
                                    {{item.payment_type_str}}
                                    <button *ngIf="item.status == 0" class="btn btn-label btn-secondary  btn-labeled btn-labeled-left mr-2">
                                        <span class="btn-label"><span class="material-icons text-warning"> watch_later </span></span>
                                        Chưa gửi
                                    </button>
                                </td>
                                <td class="text-center">
                                    <label *ngIf="item.temp_checked_origin == 1 && item.status == 1" class="checkbox checkbox-primary check-single">
                                        <input class="mail-check" type="checkbox" [(ngModel)]="item.temp_checked">
                                        <span class="input-span"></span>
                                    </label>
                                    <div *ngIf="item.temp_checked_origin == 1 && item.status == 2">
                                        <button (click)="refund(item)" class="btn btn-outline-secondary btn-icon-only btn-sm mr-2" placement="top" ngbTooltip="Hoàn thu"><span class="material-icons"> restore </span></button>
                                        <button (click)="print(item)" class="btn btn-outline-primary btn-icon-only btn-sm mr-2" placement="top" ngbTooltip="In phiếu thu"><span class="material-icons"> print
                                            </span></button>
                                        <button (click)="uploadReceipt(item)" class="btn btn-outline-warning btn-icon-only btn-sm" placement="top" ngbTooltip="Upload hóa đơn"><span class="material-icons"> receipt_long
                                            </span></button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="item.code_bidv_merge != ''" [ngClass]="{'row-disabled': item.temp_checked_origin == 0, 'row-checked' : item.temp_checked, 'row-paid' : item.status == 2}">
                                <td>
                                    <a *ngIf="item.temp_checked_origin == 1 && item.status < 2" class="avatar-img-list" (click)="showQRcode(item)" href="javascript:;">
                                        <span style="font-size: 32px;" class="material-icons"> qr_code_2 </span>
                                    </a>
                                    <img *ngIf="item.temp_checked_origin == 1 && item.status == 2" class="img-circle mt-1" src="./assets/img/icons/check-mark.png" alt="image" width="30" />
                                </td>
                                <td>
                                    <div style="font-size: 18px; font-weight: bold;" class="text-info">
                                        {{item.code_bidv_merge}}</div>
                                </td>

                                <td>
                                    <div style="font-size: 18px;">{{item.merge_name}}</div>
                                </td>
                                <td class="text-right">
                                    <div style="font-size: 18px; font-weight: bold;">{{item.merge_value| qbscurrency}}
                                    </div>
                                </td>
                                <td style="text-align: center"></td>
                                <td style="text-align: center">
                                </td>

                                <td colspan="2" class="text-center text-info">
                                    Khoản thu đã gộp
                                </td>

                                <td class="text-center">
                                    <button (click)="unMergeFinance(item)" class="btn btn-outline-info btn-icon-only btn-sm" placement="top" ngbTooltip="Hủy gộp"><span class="material-icons"> link </span></button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div *ngIf="isLoading" class="text-center m-2">
                <span class="spinner-border spinner-lg"></span>
            </div>
            <!-- <div class="mt-4 d-flex justify-content-end">
                <button class="blurButton btn btn-info btn-labeled btn-labeled-left btn-icon" (click)="mergeFinance()">
                <span class="btn-label"><span class="material-icons">link</span></span>Gộp khoản thu
            </button>
                <button class="ml-2 blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
                <span class="btn-label"><span class="material-icons">done</span></span>Thanh toán
            </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
                <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
            </button>
            </div> -->
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="blurButton btn btn-info btn-labeled btn-labeled-left btn-icon" (click)="mergeFinance()">
        <span class="btn-label"><span class="material-icons">link</span></span>Gộp khoản thu
    </button>
    <button class="ml-2 blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="click_select()">
        <span class="btn-label"><span class="material-icons">done</span></span>Thanh toán
    </button>
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>