import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/_services/app.service';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { StudentService } from 'src/app/_services/student.service';

@Component({
  selector: 'app-class-detail-time',
  templateUrl: './class-detail-time.component.html',
  styleUrls: ['./class-detail-time.component.css']
})
export class ClassDetailTimeComponent implements OnInit {
  itemClass : any;
  itemClassTime : any;

  students = [];
  filter = {
    name: "",
    khoi_code: "",
    class_code: "",
    gender: "",
    status: "",
    faculty_code: "",
    deleted: 0,
  }
  sorter = {
    sortStr: "",
  }

  // Xử lý danh sách week
  temp_class_weeks = [];

  constructor(
    public appService: AppService,
    public studentService: StudentService,
    public categoryEduService: CategoryEduService,
    public dialogRef: MatDialogRef<ClassDetailTimeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) { 
    this.itemClass = data.itemClass;
    this.itemClassTime = data.itemClassTime;
    // bind data
    this.filter.class_code = this.itemClass.class_code;

    // Xử lý danh sách week
    var temp_weeks_class = [];
    if(this.itemClass.class_time_weeks != null){
      this.itemClass.class_time_weeks = this.itemClass.class_time_weeks.replace(/,/g, "w");;
      temp_weeks_class = this.itemClass.class_time_weeks.split("w");
    }

    this.appService.weeks.forEach((element,i) => {
        this.temp_class_weeks.push({ 'code': element.code, 'value': element.value,'checked':false },);//clone
        temp_weeks_class.forEach(element_w => {
          if(element_w == element.code && this.temp_class_weeks[i].checked == false){ // && và trước đó là false
            this.temp_class_weeks[i].checked = true;//clone and false default
          }
        });
    
    });


    console.log(data);
    this.loadStudent();
    this.loadDurationClassTime();
  }

  ngOnInit(): void {

  }
  category_change(event) {
    console.log(this.filter);
    this.loadStudent();
  }
  loadStudent() {
    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    // trim name
    this.filter.name = this.filter.name.trim();
    // kiểm tra quyền khi load class
    if(this.filter.class_code == ''){
      if(!this.appService.checkFullPermission()){// nếu không có quyền thì không được load
        return;
      }
    }

    if (true) {
  
      this.studentService.loadStudent(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {

            response.data.forEach(element => {
            
              // xử lý Parents
              if(element.parents == null || element.parents == ""){
                element.parents = [];
              }else{
                // let temp = element.parents.split(",");
                try {
                  element.parents = element.parents.split(', ').map(entry => {
                    const loggedIn = entry.includes('^');
                    const [role, namePhone] = entry.replace('^', '').split(': ');
                    const name = namePhone.split(' [')[0];
                    const phone = namePhone.match(/\[(.*?)\]/)[1];
                    return { "role": role.trim(), "name": name.trim(), "phone": phone.trim(), "loggedIn": loggedIn };
                });
                } catch (error) {
                  console.log(element);
                }

              }
            });
            console.log(this.students);
            this.students = response.data;
          }
        },
        error => {

        })
    }
  }
  loadDurationClassTime() {
    let data = {
      class_code: this.itemClass.class_code,
    };
  
  
    this.categoryEduService.loadDurationClassTime(data).subscribe(
      async response => {
        console.log(response);
        if (response) {

   
        }
      },
      error => {

      })
    
  }


  cancelClick() {
    this.dialogRef.close();
  }

}
