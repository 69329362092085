import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { IoService } from 'src/app/_services/io.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FinancePrintInvoiceComponent } from 'src/app/components/finance/finance-print-invoice/finance-print-invoice.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-schedules-document',
  templateUrl: './schedules-document.component.html',
  styleUrls: ['./schedules-document.component.css']
})
export class SchedulesDocumentComponent implements OnInit {
  isLoading : boolean = false;
  isLoading_upload : boolean = false;
  isLoading_delete : boolean = false;
  list_document = [];
  cateType = environment.cateType_schedules_document;
  filter = {
    type: this.cateType,
    code_parent: "",
    value_date: "",
    subject_type: "",
    khoi_code: "",
  }
  fileUpload: File;
  myFileName = "";

  constructor(
    private categoryeduService : CategoryEduService,
    public dialog: MatDialog,
    public utilityService: UtilityService,
    public ioService: IoService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<SchedulesDocumentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public item: any) 
  {   
    console.log(item);
      this.filter.value_date = item.checkin_date;
      this.filter.subject_type = item.subject_code;
      this.filter.khoi_code = item.class_code;
  }

  ngOnInit(): void {
    this.loadDocument();
  }
  changeListener($event): void {
    this.fileUpload = $event.target.files[0];
    this.myFileName = this.utilityService.getNameByFileName(this.fileUpload.name);
    console.log(this.fileUpload);
  }
  deleteFile(item){
    // popup confirm
    let tempdata = {
      title: 'Xác nhận xóa tài liệu  ?',
      msg: 'Xác nhận',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: tempdata
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        var data = {
          type: this.cateType,
          id: item.id,
          file_url: item.file_url,
          deleted: 1,
        }
        this.isLoading_delete = true;
        this.ioService.deleteFile(data).subscribe(
          async response => {
            this.isLoading_delete = false;
            console.log(response);
            if (response.code == "ok") {
              this.loadDocument();
              this.toastr.success("", "Xóa tài liệu thành công");
            }else{
              this.toastr.warning(response.msg, "Xóa tài liệu không thành công");
            }
          },
          error => {
            this.isLoading_delete = false;
            console.error(error);
          });
      }
    });

  }
  uploadFile(){
    if(this.fileUpload == null || typeof (this.fileUpload) == "undefined"){
      this.toastr.warning("Vui lòng chọn file upload");
      return;
    }
    if(this.myFileName == ""){
      this.toastr.warning("Vui lòng nhập tên file");
      return;
    }
    console.log(this.fileUpload);
  
  
    const formData = new FormData();
    formData.append('uploadFile', this.fileUpload, this.fileUpload.name);
    formData.append('action', "UPLOAD_FILE_GOOGLE");
    formData.append('des', `${this.cateType}/${this.item.code}`);
    formData.append('name', this.myFileName);
    formData.append('type', this.cateType);
    formData.append('teacher_code', this.item.teacher_code);
    formData.append('subject_code', this.item.subject_code);
    formData.append('class_code', this.item.class_code);
    formData.append('checkin_date', this.item.checkin_date);
    this.isLoading_upload = true;
    this.ioService.uploadFile(formData).subscribe(
      async response => {
        this.isLoading_upload = false;
        if(response.code == 'ok'){
          this.toastr.success("Upload thành công");
          this.myFileName = '';
          this.fileUpload = null;
          this.loadDocument();
        }
        console.log('upload');
        console.log(response);
      },
      error => {
        this.isLoading_upload = false;
        console.error(error);
      })
  }
  loadDocument() {

    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    let sorter = {
      sortStr: 'od'
    }
    this.isLoading = true;
    this.categoryeduService.loadCategory(this.filter, sorter, pagingObj).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
            this.list_document = response.data;
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }
  onCancelClick(): void {
    this.dialogRef.close({code: 'ok', value: this.list_document.length});
  }
}
