import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
@Injectable({
  providedIn: 'root'
})
export class CategoryEduService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  getClassByGVCNCode(cate) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/getClassByGVCNCode'), cate,).map(data => { return data; });
  }
  insertCategory(cate) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu'), cate,).map(data => { return data; });
  }
  saveMultiCategorys(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/saveMultiCategorys'), data,).map(data => { return data; });
  }
  checkCode(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/checkCode'), filter,).map(data => { return data; });
  }
  loadAllCategory(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/getAll'), filter,).map(data => { return data; });
  }
  loadCategoryClassBySubject(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/getClassBySubject'), data,).map(data => { return data; });
  }
  getCategoryByCode(code, filter) {
    let data = {
      code: code,
      filter: filter,
    };
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/getCategoryByCode'), data,).map(data => { return data; });
  }
  loadCategory(filter,sorter,pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/get'), data,).map(data => { return data; });
  }
  
  update(cate) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/code/'+cate.code), cate,).map(data => { return data; });
  }
  
  updateAny(cate) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/any/code/'+cate.code), cate,).map(data => { return data; });
  }
  updateByID(cate) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/somedatabyid'), cate,).map(data => { return data; });
  }
  updateByCode(code, data) {
    let temp = {
      code: code,
      data: data,
    };
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/updateByCode'), temp,).map(data => { return data; });
  }
  ganGiaoVienChuNhiem(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/gangiaovienchunhiem/code/'+data.code), data,).map(data => { return data; });
  }
  ganToTruong(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/somedata/code/'+data.code), data,).map(data => { return data; });
  }
  countAllCategory(type) {
    return this.http.get<any>(this.appService.getApiEndpoint('categoryedu/count/'+type), {},).map(data => { return data; });
  }
  loadSomeDataTeacherAssignment(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/loadSomeDataTeacherAssignment'), data,).map(data => { return data; });
  }
  saveArrange(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/saveArrange'), data).map(data => { return data; });
  }


  
  // schedule schedule schedule schedule
  loadScheduleDetail(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('schedule/loadScheduleDetail'), data,).map(data => { return data; });
  }
  loadDurationClassTime(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('schedule/loadDurationClassTime'), data,).map(data => { return data; });
  }


}




