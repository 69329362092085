<div class="page-content fade-in-up">

    <div class="row mb-2 mt-2 justify-content-between">
        <div class="col p-0">
            <div class="d-flex justify-content-start align-items-center ">


                <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                    <mat-label>Tìm kiếm học sinh</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập họ tên học sinh" name="search" maxlength="200">
                    <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field>


                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Khối</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.khoi_code" (selectionChange)="category_khoi_change($event)" name='filter_khoi_code'>
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of khoicosos" [value]="item.code">
                            {{item.name}} - ({{item.count_class}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Lớp</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)" name="dfdfgf">
                        <mat-option value="" *ngIf="appService.checkFullPermission()">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFullPermission() && item.isAvailable == 0">
                            {{item.name}} - ({{item.student_count}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Giới tính</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.gender" (selectionChange)="category_change($event)" name="hfgr">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of appService.genders" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Trạng thái</mat-label>
                    <mat-select [disabled]="false" [(ngModel)]="filter.status" (selectionChange)="category_change($event)" name="hfgr">
                        <mat-option value="">
                            Tất cả
                        </mat-option>
                        <mat-option *ngFor="let item of appService.status_students" [value]="item.code">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                    <mat-label>Xếp theo</mat-label>
                    <mat-select [(ngModel)]="sorter.sortStr" (selectionChange)="sort_change($event)" name="hfg6r">

                        <mat-option *ngFor="let item of appService.sort_students" [value]="item.code">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>
        </div>
        <div class="col p-0 d-flex justify-content-end  align-items-center ">
            <div class="">
                <button class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon" (click)="popup_teacher_add()">
                        <span class="btn-label"><i class="ti ti ti-plus"></i></span>Thêm mới
                    </button>
            </div>
            <div class="m-1"></div>
            <div class="">
                <div ngbDropdown class="d-inline-block">
                    <!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Thêm</button> -->

                    <button class="blurButton btn btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                            <span class="btn-label"><span class="material-icons"> menu </span></span>Thêm
                        </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="popup_student_import()" class="text-primary"><span class="material-icons mr-2">
                                    file_upload
                                </span>
                                Import Excel</button>
                        <button ngbDropdownItem class="text-secondary"><span class="material-icons mr-2">
                                    file_upload
                                </span>
                                Import Excel từ CSDL MOET</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="popup_student_export()" class="text-primary"><span class="material-icons mr-2">
                                    file_download
                                </span>
                                Tải danh sách cơ bản</button>
                        <button ngbDropdownItem class="text-secondary"><span class="material-icons mr-2">
                                    file_download
                                </span>
                                Tải danh sách đầy đủ</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="popup_mode('meal')" class="text-primary"><span class="material-icons mr-2">
                                fastfood
                                </span>
                                Đăng ký ăn bán trú</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="popup_mode('camera')" class="text-primary"><span class="material-icons mr-2">
                                video_camera_front
                                </span>
                                Đăng ký Điểm danh Camera</button>
                        <button ngbDropdownItem (click)="popup_mode('camera-image')" class="text-primary"><span class="material-icons mr-2">
                                video_camera_front
                                </span>
                                Đăng ký Ảnh Camera</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="popup_arrange()" class="text-primary"><span class="material-icons mr-2">
                                    sort_by_alpha
                                </span>
                                Sắp xếp thứ tự
                                </button>

                        <button ngbDropdownItem (click)="popup_mode('changeClass')" class="text-primary"><span class="material-icons mr-2">
                                    screen_rotation_alt
                                </span>
                                Chuyển lớp</button>
                        <div class="dropdown-divider"></div>
                        <button [disabled]="!appService.checkFullPermission()" ngbDropdownItem (click)="loadDeleted()" class="text-info">
                            <span class="material-icons mr-2"> admin_panel_settings </span>
                            Học sinh đã xóa
                         </button>
                        <!-- <button ngbDropdownItem>Something else is here</button> -->
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div *ngIf="action_mode =='camera'" class=" align-items-center  d-flex justify-content-between ">
        <div class="text-blue">
            Thầy/cô tích chọn học sinh đăng ký điểm danh Camera AI sau đó nhận lưu đăng ký.
        </div>
        <div>
            <div class="p-2">
                <button class="blurButton btn  btn-warning btn-labeled btn-labeled-left btn-icon" (click)="saveRegisterCamera()">
                        <span class="btn-label"><span class="material-icons">done</span></span>Lưu đăng ký
                    </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="action_mode =''">
                        <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
                    </button>
            </div>

        </div>
    </div>
    <div *ngIf="action_mode =='changeClass'" class=" align-items-center  d-flex justify-content-between ">
        <div class="text-primary">
            Thầy/cô tích chọn học sinh và nhấn nút chuyển lớp.
        </div>
        <div>
            <div class="p-2">
                <button class="blurButton btn  btn-warning btn-labeled btn-labeled-left btn-icon" (click)="processChangeClass()">
                        <span class="btn-label"><span class="material-icons">screen_rotation_alt</span></span>Chuyển lớp
                    </button>
                <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="action_mode =''">
                        <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
                    </button>
            </div>

        </div>
    </div>
    <div *ngIf="action_mode =='meal'" class=" align-items-center  d-flex justify-content-between ">
        <div class="text-primary">
            Thầy/cô tích chọn học sinh và nhấn nút Đăng ký ăn bán trú.
        </div>
        <div>
            <div class="p-2">
                <button class="ml-2  btn  btn-primary btn-labeled btn-labeled-left " (click)="registerMeal()">
                        <span class="btn-label"><img src="./assets/img/icons/meal.png" width="22px" /></span>Đăng ký bán trú
                    </button>
                <button class="ml-2  btn  btn-primary btn-labeled btn-labeled-left " (click)="picker_meal_start.open()">
                        <span class="btn-label"><span class="material-icons">calendar_month</span></span>Câp nhật ngày bán trú
                    </button>

                <!-- <mat-form-field class="example-full-width"> -->
                <input matInput [matDatepicker]="picker_meal_start" (dateChange)="changeDateMeal($event)" style="display: none;">
                <mat-datepicker touchUi #picker_meal_start></mat-datepicker>
                <!-- </mat-form-field> -->

                <button class="ml-2  btn  btn-secondary btn-labeled btn-labeled-left " (click)="action_mode =''">
                        <span class="btn-label"><span class="material-icons">clear</span></span>Cancel
                    </button>
            </div>

        </div>
    </div>
    <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                    {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                            <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}
                            </option>
                        </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                                <i class="ti ti-angle-left"></i>
                            </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">
                        <div class="my-stt"></div>
                    </th>
                    <th>Họ tên</th>
                    <th>Tên</th>
                    <th>Lớp</th>
                    <th>Tài khoản PHHS</th>
                    <th class="text-center" style="width: 103px;">Dịch vụ </th>
                    <!-- <th class="text-right"></th> -->
                    <th *ngIf="action_mode ==''" class="no-sort" style="width: 97px;">Thao tác</th>
                    <th *ngIf="action_mode =='camera-image'" class="text-center" style="width: 180px;">
                        <img style="width: 25px; " src="./assets/img/icons/camera-student.png" />
                        <button class="ml-2 blurButton btn  btn-white btn-labeled btn-labeled-left btn-icon" (click)="action_mode =''">
                            <span class="btn-label"><span class="material-icons">check_circle</span></span>Quay lại
                        </button>
                    </th>
                    <!-- <th *ngIf="action_mode =='camera'" class="text-center" style="width: 150px;">
                        <img style="width: 30px;" src="./assets/img/icons/camera-checkin.png" />
                        <button class="btn btn-rounded" (click)="saveRegisterCamera()">
                                <span class="btn-icon">
                                    <i class="ti ti-pencil-alt"></i>
                                    Lưu
                                </span>
                            </button>
                    </th> -->
                    <th *ngIf="action_mode =='changeClass' || action_mode =='meal' || action_mode =='camera'" class="text-center" style="width: 90px;">
                        <label class="checkbox myHeader checkbox-warning check-single">
                                <input class="mail-check" type="checkbox" (change)="checkboxAll($event)">
                                <span class="input-span"></span>
                            </label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.students; let i = index">
                    <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                        <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>

                        <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)"></a>
                    </td>
                    <td>
                        <div style="font-size: 18px;">
                            <span *ngIf="item.status == 1" class="badge-danger badge-point my-blinking"></span> {{item.full_name}}
                        </div>
                        <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                            <span>{{item.code}}</span>
                            <span *ngIf="item.gender == 1" class="font-13 material-icons text-blue">boy</span>
                            <span *ngIf="item.gender == 0" class="font-13 material-icons text-pink">girl</span>
                            <!-- <span class="font-13 material-icons">  noise_control_off </span> -->
                            <span>{{item.birthday_str}}</span>

                        </div>
                    </td>
                    <td>
                        <div style="font-size: 18px;">{{item.name}}</div>
                    </td>
                    <!-- <td>{{appService.showGender(item.gender)}}</td> -->
                    <td>{{item.class_name}}</td>
                    <td>
                        <ng-container *ngFor="let ptem of item.parents; let p = index">
                            <span class="badge badge-secondary ml-2" *ngIf="ptem.name != '' || ptem.phone != ''">
                            {{ptem.name}} | {{ptem.phone}} <span *ngIf="ptem.loggedIn" class="badge-blue badge-point"></span>
                            </span>
                        </ng-container>
                    </td>
                    <td class="text-right">

                        <ng-container *ngIf="action_mode =='meal'">
                            <button *ngIf="item.meal == 1" class="mr-2  blurButton btn btn-primary btn-labeled btn-labeled-right btn-icon" placement="top" [ngbTooltip]="'Bán trú từ ngày ' + item.meal_start" (click)="changeDateMeal()" [ngClass]="{'btn-primary' : item.meal == 1}">
                                <span class="btn-label"><img src="./assets/img/icons/meal.png" width="22px" /></span>
                                    {{item.meal_start == '' ? 'Chưa chọn ngày': item.meal_start}}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="action_mode !='meal'">
                            <button *ngIf="item.meal == 1" class="mr-2  btn btn-sm btn-primary btn-icon-only" placement="top" [ngbTooltip]="'Bán trú từ ngày ' + item.meal_start" (click)="changeDateMeal()" [ngClass]="{'btn-primary' : item.meal == 1}">
                                <span class="btn-label"><img src="./assets/img/icons/meal.png" width="22px" /></span>
                            </button>
                        </ng-container>



                        <button *ngIf="item.meal == 0" class="mr-2  btn btn-sm btn-white btn-icon-only" placement="top" ngbTooltip="Không đăng ký bán trú">
                            <img [ngClass]="{'img-disable' :  item.meal == 0}" src="./assets/img/icons/meal.png" width="22px" />
                        </button>

                        <button [ngClass]="{'btn-primary' : item.checkin_type_status > 0}" class="mr-2  btn btn-sm  btn-white btn-icon-only" placement="top" [ngbTooltip]="item.checkin_type_status == 0 ? 'Không đăng ký':'Đã đăng ký ' + (item.personID == null ? '' : item.personID)">
                            <img [ngClass]="{'img-disable' : item.checkin_type_status == 0}" src="./assets/img/icons/camera.png" width="22px" />
                        </button>

                    </td>
                    <!-- <td class="text-right">{{item.price| number:0}}</td> -->

                    <td *ngIf="action_mode ==''">
                        <!-- btn-outline-primary -->
                        <button class="mr-2 btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Học sinh" (click)="popupUpdate(item, 'student')">
                            <span class="material-icons">
                                badge
                                </span>
                        </button>

                        <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Phụ huynh" (click)="popupUpdate(item, 'parent')">
                            <span class="material-icons"> people </span>
                        </button>

                    </td>
                    <td *ngIf="action_mode =='camera'" class="text-center">

                        <img *ngIf="item.checkin_status_temp" style="width: 28px;" src="./assets/img/icons/camera-checkin.png" />
                        <label class="checkbox checkbox-primary check-single ml-2">
                                <input class="mail-check" type="checkbox" [(ngModel)]="item.checkin_status_temp">
                                <span class="input-span"></span>
                            </label>
                    </td>
                    <th *ngIf="action_mode =='camera-image'" class="text-center" style="width: 50px;">
                        <button *ngIf="item.checkin_type_status == 1" class="btn btn-primary btn-fix btn-rounded" (click)='popupRegisterCameraImage(item)'>
                            <span class="btn-icon">
                                <i class="ti ti-plus"></i>
                                Đăng ký ảnh
                            </span>
                        </button>
                        <button *ngIf="item.checkin_type_status == 2" class="btn btn-info btn-fix btn-rounded" (click)='popupRegisterCameraImage(item)'>
                            <span class="btn-icon">
                                <i class="ti ti-pencil-alt"></i>
                                Cập nhật ảnh
                            </span>
                        </button>
                        <div *ngIf="item.checkin_type_status == 0" class="text-muted">
                            Chưa đăng ký
                        </div>
                    </th>
                    <td *ngIf="action_mode =='changeClass'" class="text-center">
                        <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.changeClass_temp">
                                <span class="input-span"></span>
                            </label>
                    </td>
                    <td *ngIf="action_mode =='meal'" class="text-center">
                        <label class="checkbox checkbox-primary check-single">
                                <input class="mail-check" type="checkbox"  [(ngModel)]="item.meal_temp">
                                <span class="input-span"></span>
                            </label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="students ==  undefined || students.length == 0" class="text-center text-warning m-5 p-5">
        <img style="width: 80px;" src="assets\img\icons\finder.png" alt="">
        <div>Thầy/Cô chọn lớp để tải danh sách học sinh</div>
    </div>

</div>












<div class="modal fade" id="import-student-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập Học sinh từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">
                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel
                        </a>
                    </div>
                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>

                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">

                <div class="row align-items-center">
                    <div class="col text-center">

                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadStudentExcel()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                            Import</button>

                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>