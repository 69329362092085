<div class="row mb-2 mt-2 justify-content-between">
    <div class="p-0 d-flex justify-content-start align-items-center">

        <mat-button-toggle-group [value]="type_range" #group="matButtonToggleGroup" (change)="onValChange(group.value)" appearance="" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="day">
                <span class="material-icons mr-1"> event </span> Lịch ngày</mat-button-toggle>
            <mat-button-toggle value="week">
                <span class="material-icons mr-1"> date_range </span> Lịch tuần</mat-button-toggle>
            <mat-button-toggle value="month">
                <span class="material-icons mr-1"> calendar_month </span> Lịch tháng</mat-button-toggle>
        </mat-button-toggle-group>

        <mat-form-field appearance="fill" style="width: 120px;" class=" ml-4 mr-3">
            <mat-label>Tổ bộ môn</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                    {{item.name}} ({{item.teacherSum}})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 170px;" class="mr-3">
            <mat-label>Giáo viên</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.teacher_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of teacher_list" [value]="item.code" [disabled]="false">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 120px; " class="mr-3">
            <mat-label>Lớp</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.class_code" (selectionChange)="category_change($event)" name="class_code">
                <mat-option value="" *ngIf="appService.checkFullPermission()">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of classs" [value]="item.code" [disabled]="!appService.checkFullPermission() && item.isAvailable == 0">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>






    </div>

    <div class=" p-0 d-flex justify-content-end align-items-center">
        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>

        <div ngbDropdown class="d-inline-block">

            <button class="ml-2 btn btn-lg btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                <span class="btn-label">
                    <img class="icon-image-btn" src="./assets/img/icons/excel.png" alt="image" width="20" />
                </span>
                File Excel
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="confirmDownloadExcel()" class="text-primary"><span class="material-icons mr-2">
                        file_download
                        </span>
                        Xuất file Excel
                </button>

                <button ngbDropdownItem (click)="openImport()" class="text-primary"><span class="material-icons mr-2">
                        file_upload
                        </span>
                        Nhập file Excel
                </button>

            </div>
        </div>




        <!-- <div ngbDropdown class="d-inline-block ml-2">
            <button class="btn btn-2 btn-primary btn-labeled btn-labeled-left btn-icon  mr-2" ngbDropdownToggle>
                <span class="btn-label"><span class="material-icons"> menu </span></span>
                Báo cáo
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="selectReport(1)" class="text-primary">
                    <span class="material-icons mr-1"> event </span>
                    Lịch ngày
                </button>
                <button ngbDropdownItem (click)="selectReport(2)" class="text-primary">
                    <span class="material-icons mr-1"> assignment_ind </span>
                    Báo cáo theo giáo viên
                </button>
            </div>
        </div> -->
    </div>

</div>
<div>
    <ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

        <li class="nav-item" *ngFor="let item of dateRange; let i = index">
            <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyy)">
                <div>{{item.dayOfWeek}}</div>
                <div style="font-size: large;">{{item.ddmm}}</div>
                <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
            </a>
        </li>

    </ul>
</div>
<ng-container *ngIf="type_report==1">
    <div class="row">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th style="width: 50px; text-align: center;">TT</th>
                    <th style="width: 90px;">Thứ</th>
                    <th style="width: 105px;">Ngày</th>
                    <th style="width: 110px;">Thời gian</th>
                    <th style="width: 9px;">T.Lượng</th>
                    <th>Giáo viên</th>
                    <th>Tài liệu</th>
                    <th>Lớp</th>
                    <th>Môn</th>
                    <!-- <th>Lịch</th> -->
                    <th style="text-align: center;">Nội dung / Tiêu đề</th>
                    <th style="width: 170px; text-align: center;">Checkin</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.dataListDetail; let i = index" [ngClass]="item.checkin_time == undefined ? 'row_title' : 'row_item'">
                    <td class="stt_td action-cover" style=" text-align: center; padding: 0.5rem;">
                        <div *ngIf="item.id!= undefined">
                            <ng-container *ngIf="!isLoading">
                                <a [ngClass]="appService.checkFullPermissionV2(item.teacher_code) ? 'avatar-can-hide' : ''" class="avatar-img-list" style="width: 30px; border-radius: 50%;  height: 30px;" href="javascript:;" [style.background-image]="appService.showImageTeacher(item.image_url)"></a>
                                <div *ngIf="appService.checkFullPermissionV2(item.teacher_code)" class="item-action">
                                    <div ngbDropdown class="d-inline-block">
                                        <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" ngbDropdownToggle>
                                            <span class="material-icons">
                                                menu
                                            </span>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <button ngbDropdownItem (click)="editScheduleDetail(item, 'edit')">
                                                <span class="material-icons mr-1"> edit_square </span>
                                                Sửa lịch
                                            </button>
                                            <button ngbDropdownItem (click)="editScheduleDetail(item, 'copy')">
                                                <span class="material-icons mr-1"> content_copy </span>
                                                Copy lịch
                                            </button>
                                            <!-- <button ngbDropdownItem (click)="copyToNext(item.checkin_date,[item], -1)">
                                                <span class="material-icons mr-1"> content_copy </span>
                                                Copy sang tháng này
                                            </button>
                                            <button ngbDropdownItem (click)="copyToNext(item.checkin_date,[item], 0)">
                                                <span class="material-icons mr-1"> content_copy </span>
                                                Copy sang tháng sau
                                            </button> -->
                                            <button ngbDropdownItem (click)="copyToNext(item.checkin_date,[item], 1)">
                                                <span class="material-icons mr-1"> content_copy </span>
                                                Copy sang tuần sau
                                            </button>
                                            <button ngbDropdownItem (click)="copyToNext(item.checkin_date,[item], 4)">
                                                <span class="material-icons mr-1"> content_copy </span>
                                                Copy sang 4 tuần sau
                                            </button>
                                            <button ngbDropdownItem (click)="popupDelete(item,i)">
                                            <span class="material-icons mr-1"> cancel </span> Xóa lịch
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isLoading" class="text-left">
                                <span class="spinner-border text-primary" style="height: 30px; width: 30px; border-width: 2px;"> </span>
                            </div>
                        </div>
                        <div *ngIf="item.id == undefined" class="text-left">
                            <ng-container *ngIf="!isLoading">
                                <!-- <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" (click)="editScheduleDetail(item, 'create')">
                                    <span class="material-icons">
                                        add
                                    </span>
                                </button> -->

                                <div ngbDropdown class="d-inline-block">
                                    <button class="btn btn-sm btn-white btn-icon-only btn-circle  btn-thick" ngbDropdownToggle>
                                        <span class="material-icons">
                                            add
                                        </span>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button ngbDropdownItem (click)="editScheduleDetail(item, 'create')">
                                            <span class="material-icons mr-1"> add </span>
                                            Thêm mới
                                        </button>
                                        <!-- <button ngbDropdownItem (click)="prepareCopyToNext(item.checkin_date,item, -1)">
                                            <span class="material-icons mr-1"> content_copy </span>
                                            Copy sang tháng này
                                        </button>
                                        <button ngbDropdownItem (click)="prepareCopyToNext(item.checkin_date,item, 0)">
                                            <span class="material-icons mr-1"> content_copy </span>
                                            Copy sang tháng sau
                                        </button> -->
                                        <button ngbDropdownItem (click)="prepareCopyToNext(item.checkin_date,item, 1)">
                                            <span class="material-icons mr-1"> content_copy </span>
                                            Copy sang tuần sau
                                        </button>
                                        <button ngbDropdownItem (click)="prepareCopyToNext(item.checkin_date,item, 4)">
                                            <span class="material-icons mr-1"> content_copy </span>
                                            Copy sang 4 tuần sau
                                        </button>

                                    </div>
                                </div>


                            </ng-container>
                            <div *ngIf="isLoading" class="text-left">
                                <span class="spinner-border text-primary" style="height: 30px; width: 30px; border-width: 2px;"> </span>
                            </div>
                        </div>
                    </td>
                    <td>{{item.checkin_day}}</td>
                    <td>{{item.checkin_date}}</td>
                    <td>
                        {{item.checkin_time}}
                    </td>
                    <td class="text-center">{{item.checkin_duration}}</td>
                    <td>
                        <div *ngIf="item.id!= undefined">
                            <ng-container *ngIf="item.isAvailbale == 0">
                                <button placement="top" ngbTooltip="Không thể đổi giáo viên" class="btn btn-outline-secondary btn-icon mr-2" style="padding: 2px 8px 2px 5px;">
                                <span class="text-muted mr-1">
                                    <span class="material-icons"> assignment_ind </span>
                                </span>
                                <span>{{item.teacher_name}}</span>
                            </button>
                            </ng-container>
                            <ng-container *ngIf="item.isAvailbale == 1">
                                <button (click)="openSelectTeacher(item,i)" [ngbTooltip]="'Đổi giáo viên'" placement="top" class="btn btn-outline-primary btn-icon mr-2" style="padding: 2px 8px 2px 5px;">
                                    <span class="btn-label mr-1 "
                                        [ngClass]="item.checkin_name != '' ? '' : 'text-warning'">
                                        <span class="material-icons"> assignment_ind </span>
                                    </span>
                                    <span>{{item.teacher_name}}</span>
                                </button>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        <button *ngIf="item.id!= undefined" placement="top" ngbTooltip="Tài liệu" (click)="uploadDocument(item)" [ngClass]="(item.document_count == null || item.document_count == 0) ? 'btn-default' : 'btn-primary'" class=" btn btn-labeled btn-labeled-left mr-2">
                            <span class="btn-label">
                                <span class="material-icons"> attach_file </span>
                            </span>
                            <span>{{(item.document_count == null || item.document_count == 0) ? "0" : '' + item.document_count }}</span>
                        </button>
                    </td>
                    <td>
                        <a *ngIf="item.type == 0" placement="top" ngbTooltip="Học trực tiếp" href="javascript:;">
                            <img class="m-1" src="./assets/img/icons/location.png" alt="image" width="20" />
                        </a>
                        <a *ngIf="item.type == 1" placement="top" ngbTooltip="Học Online" href="javascript:;">
                            <img class="m-1" src="./assets/img/icons/meet.png" alt="image" width="20" />
                        </a>
                        <a *ngIf="item.type == 2" placement="top" ngbTooltip="Học Online" href="javascript:;">
                            <img class="m-1" src="./assets/img/icons/zoom.png" alt="image" width="20" />
                        </a>
                        {{item.class_name}}
                    </td>
                    <td>{{item.subject_name}}</td>
                    <!-- <td>{{item.checkin_name}}</td> -->
                    <td>
                        <ng-container *ngIf="item.id!= undefined">
                            <div class="note-cover">
                                <input type="text" [(ngModel)]="item.note" [name]="'note_'+i" class="note-input">
                                <button class="btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Lưu" (click)="saveNoteOne(item)">
                                <span *ngIf="!isLoading_note" class="material-icons"> done </span>
                                <span *ngIf="isLoading_note" class="spinner-border"></span>
                            </button>
                            </div>
                        </ng-container>
                    </td>
                    <td class="checkin_status_cover">
                        <ng-container *ngIf="item.id != undefined">
                            <button class="btn btn-icon-only btn-sm mr-1" [ngClass]="item.checkin_status == 1 ? 'btn-outline-primary':'btn-outline-secondary'" placement="top" ngbTooltip="Đúng giờ">
                            <span class="material-icons"> check_circle </span>
                        </button>
                            <button class="btn btn-icon-only btn-sm mr-1" [ngClass]="item.checkin_status == 2 ? 'btn-outline-warning':'btn-outline-secondary'" placement="top" ngbTooltip="Muộn">
                            <span class="material-icons"> report </span>
                        </button>
                            <button class="btn btn-icon-only btn-sm mr-1" [ngClass]="item.checkin_status == 3 ? 'btn-outline-danger':'btn-outline-secondary'" placement="top" ngbTooltip="Vắng">
                            <span class="material-icons"> report </span>
                        </button>

                            <button class="btn btn-icon-only btn-sm ml-4" [ngClass]="item.checkinList_times.length > 0 ? 'btn-outline-info':'btn-outline-secondary'" placement="top" [ngbTooltip]="item.checkinList_times.length > 0 ? item.checkinList_times.length + ' chi tiết':'Không có dữ liệu điểm danh'"
                                (click)="showCheckinDetail(item)">
                            <span class="material-icons"> list </span>
                        </button>


                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</ng-container>
<ng-container *ngIf="type_report==2">
    <div class="row mb-2">
        <div class="col-12 d-flex justify-content-between">
            <div>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-primary"> check_circle </span>
                    </span>
                    Đúng giờ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-warning"> report </span>
                    </span>
                    Muộn/trễ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-danger"> report </span>
                    </span>
                    Vắng
                </button>
            </div>
            <div>

            </div>
        </div>
    </div>
</ng-container>



<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<app-nodata *ngIf="!isLoading && this.dataListDetail.length ==0" msg="Không có dữ liệu"></app-nodata>


<div class="modal fade" id="import-schedule-detail">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Tạo lịch dạy từ file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">


                    <div class="form-group mb-4" style="font-size: large;">
                        <a class="text-warning" (click)="popup_export()"> <span class="material-icons">file_download</span> Tải mẫu nhập Excel
                        </a>
                    </div>

                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>

                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>

                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>
                <div *ngIf="!import_result.result && import_result.msg != ''" class="text-center">
                    <div class="text-danger">Có lỗi xảy ra: {{import_result.msg}}</div>
                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">

                <div class="row align-items-center">
                    <div class="col text-center">

                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadExcel()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> file_upload </span></span>
                            Import</button>

                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>