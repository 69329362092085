import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  period_thcs_list = [
    {id: 1, code: 'thcs_period_dn', name:"Đầu năm"},
    {id: 2, code: 'thcs_period_gki', name:"Giữa kỳ I"},
    {id: 3, code: 'thcs_period_cki', name:"Cuối kỳ I"},
    {id: 4, code: 'thcs_period_gkii', name:"Giữa kỳ II"},
    {id: 5, code: 'thcs_period_ckii', name:"Cuối kỳ II"},
    {id: 5, code: 'thcs_period_cn', name:"Cuối năm"},
  ]; 
  period_th_list = [
    {id: 1, code: 'th_period_ht', name:"Hàng tháng"},
    {id: 2, code: 'th_period_hki', name:"Học kỳ I"},
    {id: 3, code: 'th_period_cnh', name:"Cuối năm học"},
  ]; 
  kqmhvhdgdList = [
    {id: 1, code: 'Hoàn thành Tốt', name:"Hoàn thành Tốt"},
    {id: 2, code: 'Hoàn thành', name:"Hoàn thành"},
    {id: 3, code: 'Chưa hoàn thành', name:"Chưa hoàn thành"},
  ]; 
  kqdgnlvpcList = [
    {id: 1, code: 'Tốt', name:"Tốt"},
    {id: 2, code: 'Đạt', name:"Đạt"},
    {id: 3, code: 'Cần cố gắng', name:"Cần cố gắng"},
  ]; 
  // Nhận xét môn toán
  th_toan_1_list = [
    {id: 1, code: 'nhanh', name:"Nhanh", checked: false},
    {id: 2, code: 'dung', name:"Đúng", checked: false},
    {id: 3, code: 'chuadung', name:"Chưa đúng", checked: false},
    {id: 4, code: 'chuanhanh', name:"Chưa nhanh", checked: false},
  ]; // Nhận xét môn toán
  th_toan_2_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 'nhanh', name:"Nhanh", checked: false},
    {id: 3, code: 'chuanhanh', name:"Chưa nhanh", checked: false},
    {id: 4, code: 'chuacanthan', name:"Chưa cẩn thận", checked: false},
  ]; // Nhận xét môn toán
  th_toan_3_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 'nhanh', name:"Nhanh", checked: false},
    {id: 3, code: 'chuadung', name:"Chưa đúng", checked: false},
    {id: 4, code: 'chuacanthan', name:"Chưa cẩn thận", checked: false},
  ]; // Nhận xét môn toán
  th_toan_4_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 'canthan', name:"Cẩn thận", checked: false},
    {id: 3, code: 'chuadung', name:"Chưa đúng", checked: false},
    {id: 4, code: 'chuanhanh', name:"Chưa nhanh", checked: false},
  ]; // Nhận xét môn toán
  th_toan_5_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 'nhanh', name:"Nhanh", checked: false},
    {id: 3, code: 'chuadung', name:"Chưa đúng", checked: false},
    {id: 4, code: 'chuanhanh', name:"Chưa nhanh", checked: false},
  ]; // Nhận xét môn toán
  th_toan_6_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 'nhanh', name:"Nhanh", checked: false},
    {id: 3, code: 'chuadung', name:"Chưa đúng", checked: false},
    {id: 4, code: 'chuanhanh', name:"Chưa nhanh", checked: false},
  ]; // Nhận xét môn toán
  th_toan_7_list = [
    {id: 1, code: '1', name:"Có khả năng tư duy, lập luận toán học", checked: false},
    {id: 2, code: '2', name:"Trình bày và kiểm tra lại được cách thức giải quyết vấn đề đã thực hiện", checked: false},
    {id: 3, code: '3', name:"Tự tin khi trả lời câu hỏi, khi trình bày, thảo luận các nội dung toán học", checked: false},
    {id: 4, code: '4', name:"Vận dụng được kiến thức toán học vào thực tế", checked: false},
  ]; // Nhận xét môn toán


  // Nhận xét môn tiếng việt
  th_tiengviet_1_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 'dep', name:"Đẹp", checked: false},
    {id: 3, code: 'chuacanthan', name:"Chưa cẩn thận", checked: false},
  ]; 
  th_tiengviet_2_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 'dep', name:"Đẹp", checked: false},
    {id: 3, code: 'tbss', name:"Trình bày sạch sẽ", checked: false},
  ]; 
  th_tiengviet_3_list = [
    {id: 1, code: 'chuanhanh', name:"Chưa nhanh", checked: false},
    {id: 2, code: 'chuacanthan', name:"Chưa cẩn thận", checked: false},
    {id: 3, code: 'cslct', name:"Còn sai lỗi chính tả", checked: false},
  ]; 
  th_tiengviet_4_list = [
    {id: 1, code: 'ngdy', name:"Ngắn gọn, đủ ý", checked: false},
    {id: 2, code: 'vchddt', name:"Viết câu hay, diễn đạt tốt", checked: false},
    {id: 3, code: 'ddct', name:"Diễn đạt chưa tốt", checked: false},
  ]; 
  th_tiengviet_5_list = [
    {id: 1, code: 'dung', name:"Đúng", checked: false},
    {id: 2, code: 't', name:"To, rõ ràng, trôi chảy", checked: false},
    {id: 3, code: 'docnho', name:"Đọc nhỏ", checked: false},
  ]; 
  th_tiengviet_6_list = [
    {id: 1, code: '1', name:"Hiểu đúng nội dung văn bản", checked: false},
    {id: 2, code: '2', name:"Hiểu đúng hình thức văn bản", checked: false},
    {id: 3, code: '3', name:"Biết liên hệ bản thân, rút ra bài học từ văn bản đã đọc", checked: false},
  ]; 
  th_tiengviet_7_list = [
    {id: 1, code: '1', name:"To, rõ ràng", checked: false},
    {id: 2, code: '2', name:"Nhỏ, chưa rõ ý, còn rụt rè", checked: false},
    {id: 3, code: '3', name:"Diễn đạt tốt, sử dụng được ngôn ngữ cơ thể", checked: false},
  ]; 
  th_tiengviet_8_list = [
    {id: 1, code: '1', name:"Biết lắng nghe", checked: false},
    {id: 2, code: '2', name:"Hỏi đáp, thảo luận thông tin phù hợp", checked: false},
    {id: 3, code: '3', name:"Kể hoặc ghi lại được nội dung đã lắng nghe", checked: false},
  ];
  // tiếng anh 
  th_tienganh_1_list = [
    {id: 1, code: '1', name:"Nghe nhanh, chính xác", checked: false},
    {id: 2, code: '2', name:"Nghe, hiểu nội dung chính", checked: false},
    {id: 3, code: '3', name:"Nghe được mẫu câu", checked: false},
    {id: 3, code: '4', name:"Nghe được từ vựng", checked: false},
    {id: 3, code: '5', name:"Chưa nghe được", checked: false},
  ]; 
  th_tienganh_2_list = [
    {id: 1, code: '1', name:"Tự tin, diễn đạt tốt", checked: false},
    {id: 2, code: '2', name:"Phát âm, ngữ điệu tốt", checked: false},
    {id: 3, code: '3', name:"Ngôn ngữ cơ thể tốt", checked: false},
    {id: 3, code: '4', name:"Rụt rè, diễn đạt chưa rõ", checked: false},
    {id: 3, code: '5', name:"Phát âm, ngữ điệu chưa tốt", checked: false},
  ]; 
  th_tienganh_3_list = [
    {id: 1, code: '1', name:"Đọc, hiểu tốt", checked: false},
    {id: 2, code: '2', name:"Đọc, hiểu nội dung chính", checked: false},
    {id: 3, code: '3', name:"Vốn từ vựng tốt", checked: false},
    {id: 3, code: '4', name:"Vốn từ vựng còn ít", checked: false},
    {id: 3, code: '5', name:"Đọc, hiểu chưa tốt", checked: false},
  ]; 
  th_tienganh_4_list = [
    {id: 1, code: '1', name:"Ngữ pháp và mẫu câu tốt", checked: false},
    {id: 2, code: '2', name:"Viết đúng ngữ pháp", checked: false},
    {id: 3, code: '3', name:"Nắm được mẫu câu cơ bản", checked: false},
    {id: 3, code: '4', name:"Ngữ pháp, mẫu câu chưa tốt", checked: false},
    {id: 3, code: '5', name:"Viết chưa cẩn thận", checked: false},
  ]; 
  // Kỹ năng sống
  th_kns_2_list = [
    {id: 1, code: '1', name:"Hiểu rõ kỹ năng", checked: false},
    {id: 2, code: '2', name:"Hiểu chưa đầy đủ", checked: false},
    {id: 3, code: '3', name:"Chưa hiểu", checked: false},
  ]; 
  th_kns_3_list = [
    {id: 1, code: '1', name:"Tốt", checked: false},
    {id: 2, code: '2', name:"Đạt", checked: false},
    {id: 3, code: '3', name:"Chưa đạt", checked: false},
  ]; 
  th_kns_4_list = [
    {id: 1, code: '1', name:"Tốt", checked: false},
    {id: 2, code: '2', name:"Đạt", checked: false},
    {id: 3, code: '3', name:"Chưa đạt", checked: false},
  ]; 
  // công tác chăm sóc
  th_ctcs_1_list = [
    {id: 1, code: '1', name:"Tự giác", checked: false},
    {id: 2, code: '2', name:"Chưa tự giác", checked: false},
  ]; 
  th_ctcs_2_list = [
    {id: 1, code: '1', name:"Đầy đủ", checked: false},
    {id: 2, code: '2', name:"Chưa đúng quy định", checked: false},
  ]; 
  th_ctcs_3_list = [
    {id: 1, code: '1', name:"Sạch sẽ", checked: false},
    {id: 2, code: '2', name:"Chưa tự giác", checked: false},
  ]; 
  // công tác chăm sóc
  th_ythuc_1_list = [
    {id: 1, code: '1', name:"Giữ gìn sách vở và đồ dùng học tập cẩn thận", checked: false},
    {id: 2, code: '2', name:"Tích cực xây dựng bài và trình bày ý kiến", checked: false},
    {id: 3, code: '3', name:"Có khả năng tự học, tự giác và chăm chỉ hoàn thành các bài tập", checked: false},
  ]; 
  th_ythuc_2_list = [
    {id: 1, code: '1', name:"Biết lắng nghe, chia sẻ, tìm kiếm sự hợp tác", checked: false},
    {id: 2, code: '2', name:"Có khả năng giải quyết vấn đề, có nhiều ý tưởng sáng tạo", checked: false},
    {id: 3, code: '3', name:"Có ý thức học hỏi để mở rộng vốn hiểu biết", checked: false},
  ]; 
  th_ythuc_3_list = [
    {id: 1, code: '1', name:"Đi học đầy đủ, đúng giờ", checked: false},
    {id: 2, code: '2', name:"Tự chịu trách nhiệm, biết nhận lỗi và tự sửa lỗi", checked: false},
    {id: 3, code: '3', name:"Thực hiện các nội quy, quy định của trường, bảo vệ của công, giữ vệ sinh chung", checked: false},
  ]; 
  th_ythuc_4_list = [
    {id: 1, code: '1', name:"Tích cực tham gia các hoạt động của trường, lớp", checked: false},
    {id: 2, code: '2', name:"Biết quan tâm, giúp đỡ, thân thiện với những người xung quanh", checked: false},
    {id: 3, code: '3', name:"Thể hiện được tình yêu quê hương, đất nước, yêu thiên nhiên qua những việc làm cụ thể", checked: false},
  ]; 
  th_phamchatchuyeu_list = [
    {id: 1, code: '1', name:"Yêu nước", value: ""},
    {id: 2, code: '2', name:"Nhân ái", value: ""},
    {id: 3, code: '3', name:"Chăm chỉ", value: ""},
    {id: 4, code: '4', name:"Trung thực", value: ""},
    {id: 5, code: '5', name:"Trách nhiệm", value: ""},
  ]; 
  th_nanglucchung_list = [
    {id: 1, code: '1', name:"Tự chủ và tự học", value: ""},
    {id: 2, code: '2', name:"Giao tiếp và hợp tác", value: ""},
    {id: 3, code: '3', name:"Giải quyết vấn đề và sáng tạo", value: ""},
  ]; 
  th_nanglucdacthu_list = [
    {id: 1, code: '1', name:"Ngôn ngữ", value: ""},
    {id: 2, code: '2', name:"Tính toán", value: ""},
    {id: 3, code: '3', name:"Khoa học", value: ""},
    {id: 4, code: '4', name:"Công nghệ", value: ""},
    {id: 5, code: '5', name:"Tin học", value: ""},
    {id: 6, code: '6', name:"Thẩm mỹ", value: ""},
    {id: 7, code: '7', name:"Thể chất", value: ""},
  ]; 
  th_danhgiaketquagiaoduc = [
    {id: 1, code: '1', name:"Hoàn thành Xuất sắc", value: ""},
    {id: 2, code: '2', name:"Hoàn thành Tốt", value: ""},
    {id: 3, code: '3', name:"Hoàn thành", value: ""},
    {id: 4, code: '4', name:"Chưa hoàn thành", value: ""}
  ]; 
  // cấp THCS
  ykien_gvcn_thcs = [
    {id: 1, code: '1', name:"Ý thức", value: ""},
    {id: 2, code: '2', name:"Tác phong", value: ""},
    {id: 3, code: '3', name:"Học tập", value: ""},
    {id: 4, code: '4', name:"Cần phát huy", value: ""},
    {id: 5, code: '5', name:"Cần sửa đổi", value: ""},
  ]; 
  danhgia_gvcn_thcs_ck = [
    {id: 1, code: '1', name:"Rèn luyện", value: "", rows: 1},
    {id: 2, code: '2', name:"Học tập", value: "", rows: 1},
    {id: 3, code: '3', name:"Khen thưởng", value: "", rows: 3},
    {id: 4, code: '4', name:"Đạt thành tích khác", value: "", rows: 3},
  ]; 
  constructor() { }
}