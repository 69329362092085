<form class="">
    <h5 class="mat-dialog-title">Lớp <b>{{itemClass.class_code}}</b> .Lịch <b>{{itemClass.class_time_names}} </b></h5>
    <mat-dialog-content>
        <div class="modal-body p-2">
            <div class="d-flex justify-content-between align-items-start">
                <div style=" border-right: 1px solid gray; padding-right: 10px; ">
                    <div>Thời gian: {{itemClass.time_data_sum.minStartDate}} - {{ itemClass.time_data_sum.maxEndDate}} </div>
                    <app-progress-bar [doing]="itemClass.time_data_sum.duration_day_done" [left]="itemClass.time_data_sum.duration_day_left"></app-progress-bar>

                    <div class="mt-2">Buổi học: 333/33433 </div>
                    <app-progress-bar [doing]="itemClass.time_data_sum.duration_day_done" [left]="itemClass.time_data_sum.duration_day_left"></app-progress-bar>

                    <section class="mt-2 mb-1 weeks-checkbox">
                        <div class="mb-1">Lịch học</div>
                        <mat-checkbox *ngFor="let item of temp_class_weeks; let i = index" [color]="'primary'" [(ngModel)]="item.checked" [name]="'temp_class_weeks'+i">{{item.value}}</mat-checkbox>
                    </section>
                </div>
                <div>
                    <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                        <mat-label>Giới tính</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="filter.gender" (selectionChange)="category_change($event)" name="hfgr">
                            <mat-option value="">
                                Tất cả
                            </mat-option>
                            <mat-option *ngFor="let item of appService.genders" [value]="item.code">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" style="width: 100px; margin-bottom: 0px;" class="ml-2">
                        <mat-label>Trạng thái</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="filter.status" (selectionChange)="category_change($event)" name="hfgr">
                            <mat-option value="">
                                Tất cả
                            </mat-option>
                            <mat-option *ngFor="let item of appService.status_students" [value]="item.code">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" style="width: 120px; margin-bottom: 0px;" class="ml-2">
                        <mat-label>Xếp theo</mat-label>
                        <mat-select [(ngModel)]="sorter.sortStr" (selectionChange)="category_change($event)" name="hfg6r">

                            <mat-option *ngFor="let item of appService.sort_students" [value]="item.code">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="mt-3">
                <table class="table table-head-purple table-hover" id="datatable">
                    <thead class="thead-default thead-lg">
                        <tr>
                            <th style="width: 50px; text-align: center;">
                                <div class="my-stt"></div>
                            </th>
                            <th>Họ tên</th>
                            <th>Tên</th>
                            <th>Điểm danh</th>
                            <th class="no-sort" style="width: 97px;">Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of this.students; let i = index">
                            <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                                <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span>

                                <a class="avatar-img-list" style="width: 40px;  height: 40px;" href="javascript:;" [style.background-image]="appService.showImageStudent(item.image_url,item.gender)"></a>
                            </td>
                            <td>
                                <div style="font-size: 18px;">
                                    <span *ngIf="item.status == 1" class="badge-danger badge-point my-blinking"></span> {{item.full_name}}
                                </div>
                                <div [ngClass]="{'text-danger': false}" style="font-size: 13px;">
                                    <span>{{item.code}}</span>
                                    <span *ngIf="item.gender == 1" class="font-13 material-icons text-blue">boy</span>
                                    <span *ngIf="item.gender == 0" class="font-13 material-icons text-pink">girl</span>
                                    <!-- <span class="font-13 material-icons">  noise_control_off </span> -->
                                    <span>{{item.birthday_str}}</span>

                                </div>
                            </td>
                            <td>
                                <div style="font-size: 18px;">{{item.name}}</div>
                            </td>

                            <td>

                            </td>
                            <td>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end">

        <button (click)="cancelClick()" class="btn btn-default  btn-labeled btn-labeled-left btn-icon">
        <span class="btn-label"><span class="material-icons"> close </span></span>
        Đóng
    </button>
    </mat-dialog-actions>
</form>